import React from "react"
import PropTypes from 'prop-types'

class SnackAd extends React.Component {

  constructor(props) {
      super(props);
  }

  componentDidMount () {
    (function(){

    var site_id = 2512;
    var data_site_id = 0;

    var sn_cb = new Date().getMonth();
    var snack_hb = document.createElement('script');
    snack_hb.src = 'https://cdn-header-bidding.snack-media.com/assets/js/snack-loader/'+site_id+'?cb='+sn_cb;
    snack_hb.id = 'snack_ads';
    if(data_site_id){
      snack_hb.setAttribute('data-site-id',data_site_id);
      }

    document.body.appendChild(snack_hb);
    snack_hb.onerror = function(){
      document.body.removeChild(snack_hb);
      var snack_hb2 = document.createElement('script');
      snack_hb2.src = 'https://cdn2-header-bidding.snack-media.com/assets/js/snack-loader/'+site_id+'?cb='+sn_cb;
      snack_hb2.id = 'snack_ads';
      if(data_site_id){
          snack_hb2.setAttribute('data-site-id',data_site_id);
        }
        document.body.appendChild(snack_hb2);
      };
    })();
  }

render () {
  const ad_type = this.props.ad_type;
  const ad_num = this.props.ad_num;
  const ad_style = ad_type ==="leader" ? {minHeight: "250px", textAlign: "center"}
    : {minHeight: "600px", textAlign:"center"};
  const ad_id =
    ad_type === "leader" &&  ad_num === 1 ? "snack_ldb"
    : ad_type === "leader" &&  ad_num === 2 ? "snack_dex2"
    : ad_type === "leader" &&  ad_num === 5 ? "snack_dex3"
    : ad_type === "leader" &&  ad_num === 4 ? "snack_dex4"
    : ad_type === "skyscraper" &&  ad_num === 1 ? "snack_dmpu"
    : ad_type === "skyscraper" &&  ad_num === 2 ? "snack_dex5"
    : "other";

  const ad_container = ad_type ==="leader" ? {margin: "auto"}
      : {margin: "auto", desktop: "position:-webkit-sticky", position: "sticky", top: "65px"};

    return (
      <div style = {ad_container}>
        <div id={ad_id} style={ad_style}></div>
      </div>
    );
  }
}

export default SnackAd

SnackAd.propTypes = {
  ad_type: PropTypes.string,
  ad_num:PropTypes.number
};

import React from "react"
import PropTypes from 'prop-types';

class SnackAd extends React.Component {

  constructor(props) {
      super(props);
  }

  componentDidMount () {
      this.isMobile = window.innerWidth < 1200;
  }

render () {

  const ad_type = this.props.ad_type;
  const ad_num = this.props.ad_num;

  // console.log("window:"+window.innerWidth);
  // console.log("windowisMobile:"+isMobile);

  const ad_style = ad_type ==="leader" ? {minHeight: "250px", textAlign: "center"}
    : this.isMobile === true ? {minHeight: "250px", textAlign: "center"}
    : {minHeight: "600px", textAlign:"center"};

    const ad_id =
      ad_type === "leader" &&  ad_num === 1 ? "snack_ldb"
      : ad_type === "leader" &&  ad_num === 2 ? "snack_dex2"
      : ad_type === "leader" &&  ad_num === 5 ? "snack_dex3"
      : ad_type === "leader" &&  ad_num === 4 ? "snack_dex4"
      : ad_type === "skyscraper" &&  ad_num === 1 ? "snack_dmpu"
      : ad_type === "skyscraper" &&  ad_num === 2 ? "snack_dex5"
      : "other";

      const ad_show =
        this.isMobile === true && ad_type === "leader" ? "show"
        : this.isMobile === true && ad_type === "skyscraper" ? "dontshow"
        : this.isMobile === false && ad_num === 2 && ad_type === "leader" ? "dontshow"
        : this.isMobile === false && ad_num === 5  ? "dontshow"
        : "show";

  const ad_container = ad_type ==="leader" ? {margin: "auto"}
      : this.isMobile === true ? {margin: "auto"}
      : {margin: "auto", desktop: "position:-webkit-sticky", position: "sticky", top: "65px"};

      if(ad_show==="show"){
        return (
          <div style = {ad_container}>
            <div id={ad_id} style={ad_style}></div>
          </div>
        )
      }
      else {
        return null;
      }

      }
      }

export default SnackAd

SnackAd.propTypes = {
  ad_type: PropTypes.string,
  ad_num:PropTypes.number
};
